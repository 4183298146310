import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callCustomerViewGet, callCustomerPost, callCustomerPut } from "../../actions/customers"
import Sidebar from "../../../../../common/containers/Sidebar"
import Card from "../../../../../common/containers/Card"

let CustomerEdit = ({ methods: { callCustomerPost, callCustomerViewGet, callCustomerPut }, view }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [name, setName] = useState('')
    const [pageAttr, setPageAttr] = useState({})

    useEffect(() => {
        let tabs
        if (!id) {
            tabs = [    
                {
                    active: true,
                    to: '/register/customer/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callCustomerViewGet(id)
            tabs = [
                {
                    active: true,
                    to: '/register/customer/edit/' + id,
                    label: 'Editar'
                }
            ]
        }

        setPageAttr({
            title: 'Cliente - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callCustomerViewGet])

    useEffect(() => {
        setName(view.name)
    }, [view])

    const finishedSubmit = () => {
        setName('')
        window.navigate('/register/customer')
    }

    const submit = (e) => {
        e.preventDefault()

        let data = {
            name,
        }

        if (!id) {
            callCustomerPost(data, () => finishedSubmit())
        } else {
            callCustomerPut(id, data, () => finishedSubmit())
        }
    }

    return (
        <Sidebar pWidth="80%">
            <Card {...pageAttr} closeTo='/register/customer'>
                <form onSubmit={submit}>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-6 form-group">
                                    <label className="required">Nome:</label>
                                    <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix text-left mt-3">
                        <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                    </div>
                </form>
            </Card>
        </Sidebar>
    )
}

const mapStateToProps = ({ register: { customers: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callCustomerViewGet,
            callCustomerPost,
            callCustomerPut,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit)