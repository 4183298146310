import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_CUSTOMER_LIST = {
    type: 'CUSTOMER_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_CUSTOMER_VIEW = {
    type: 'CUSTOMER_VIEW',
    payload: {
        name: '',
    },
};

let ACTION_CUSTOMER_SELECT = {
    type: 'CUSTOMER_SELECT',
    payload: [],
};

let callCustomerListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/customer?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_CUSTOMER_LIST.payload.rows = data.rows
        ACTION_CUSTOMER_LIST.payload.total = data.total
        ACTION_CUSTOMER_LIST.payload.pagination = data.pagination
        dispatch(ACTION_CUSTOMER_LIST)
    })
}

let callCustomerViewGet = (id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/customer/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_CUSTOMER_VIEW.payload.name = data.name
        dispatch(ACTION_CUSTOMER_VIEW)
    })
}

let callCustomerPost = (data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/admin/register/customer', data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callCustomerPut = (id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put('/admin/register/customer/' + id, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callCustomerClearView = () => (dispatch) => {
    ACTION_CUSTOMER_VIEW.payload.name = ''
    dispatch(ACTION_CUSTOMER_VIEW)
}

let callCustomerDelete = (id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete('/admin/register/customer/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callCustomerSelectGet = (filter = {}) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/customer-select').then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_CUSTOMER_SELECT.payload = data
        dispatch(ACTION_CUSTOMER_SELECT)
    })
}

export { callCustomerListGet, callCustomerViewGet, callCustomerPost, callCustomerPut, callCustomerDelete, callCustomerClearView, callCustomerSelectGet, ACTION_CUSTOMER_LIST, ACTION_CUSTOMER_VIEW, ACTION_CUSTOMER_SELECT }