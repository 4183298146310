import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import EnvironmentList from './EnvironmentList'
import EnvironmentEdit from './EnvironmentEdit'
import { useState } from 'react'
import Card from '../../../../../common/containers/Card'

let EnvironmentPage = ({ setBreadcrumbs }) => {
    const [pageAttr, setPageAttr] = useState({})

    useState(() => {
        setBreadcrumbs([
            { label: 'Dashboard', href: '/' },
            { label: 'Cadastro - Ambientes', active: true }
        ])
    }, [setBreadcrumbs])

    return (
        <Card {...pageAttr} showClose={false}>
            <Routes>
                <Route path='/edit/:id' element={<EnvironmentEdit setPageAttr={setPageAttr} />} />
                <Route path='/add' element={<EnvironmentEdit setPageAttr={setPageAttr} />} />
                <Route index element={<EnvironmentList setPageAttr={setPageAttr} />} />
            </Routes>
        </Card>
    )
}

export default connect()(EnvironmentPage)