import { ACTION_ENVIRONMENT_LIST, ACTION_ENVIRONMENT_VIEW } from "../actions/environments";

let initialReducer = {
    environments: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            software: {
                id: '',
                name: '',
            },
            customer: {
                id: '',
                name: '',
            },
            domain: '',
        },
    },
}

let SoftwareReducer = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_ENVIRONMENT_LIST.type:
            return {
                ...state,
                environments: {
                    ...state.environments,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_ENVIRONMENT_VIEW.type:
            return {
                ...state,
                environments: {
                    ...state.environments,
                    view: {
                        software: action.payload.software,
                        customer: action.payload.customer,
                        domain: action.payload.domain
                    },
                },
            };
        default:
            return state;
    }
}

export default SoftwareReducer