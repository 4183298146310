import CustomerPage from "../containers/Customer/CustomerPage"
import UserPage from "../containers/User/UserPage"

let RegisterRoutes = [
    {
        path: 'register/user/*',
        container: UserPage,
    },
    {
        path: 'register/customer/*',
        container: CustomerPage,
    },
]

export default RegisterRoutes