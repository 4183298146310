import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import UserList from './UserList'
import UserEdit from './UserEdit'
import { useState } from 'react'
import Card from '../../../../../common/containers/Card'

let UserPage = ({setBreadcrumbs}) => {
    const [pageAttr, setPageAttr] = useState({})

    useState(() => {
        setBreadcrumbs([
            { label: 'Dashboard', href: '/' },
            { label: 'Cadastro - Usuários', active: true }
        ])
    }, [setBreadcrumbs])

    return (
        <Card {...pageAttr} showClose={false}>
            <Routes>
                <Route path='/edit/:id' element={<UserEdit setPageAttr={setPageAttr} />} />
                <Route path='/add' element={<UserEdit setPageAttr={setPageAttr} />} />
                <Route index element={<UserList setPageAttr={setPageAttr} />} />
            </Routes>
        </Card>
    )
}

export default connect()(UserPage)