import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import CustomerList from './CustomerList'
import CustomerEdit from './CustomerEdit'
import { useState } from 'react'
import Card from '../../../../../common/containers/Card'

let CustomerPage = ({ setBreadcrumbs }) => {
    const [pageAttr, setPageAttr] = useState({})

    useState(() => {
        setBreadcrumbs([
            { label: 'Dashboard', href: '/' },
            { label: 'Cadastro - Clientes', active: true }
        ])
    }, [setBreadcrumbs])

    return (
        <Card {...pageAttr} showClose={false}>
            <Routes>
                <Route path='/edit/:id' element={<CustomerEdit />} />
                <Route path='/add' element={<CustomerEdit />} />
                <Route index element={<CustomerList setPageAttr={setPageAttr} />} />
            </Routes>
        </Card>
    )
}

export default connect()(CustomerPage)