import { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"

const Menu = () => {
    const location = useLocation()

    const menu = useMemo(() => [
        {
            label: 'Cadastros',
            icon: 'fas fa-file-alt',
            to: '/register',
            child: [
                {
                    label: 'Usuários',
                    to: '/register/user',
                    icon: 'fas fa-users',
                },
                {
                    label: 'Clientes',
                    to: '/register/customer',
                    icon: 'fas fa-building',
                },
            ]
        },
        {
            label: 'Softwares',
            icon: 'fas fa-file-alt',
            to: '/software',
            child: [
                {
                    label: 'Ambientes',
                    to: '/software/environment',
                    icon: 'fas fa-cloud-upload-alt',
                },
            ]
        },
    ], [])

    return (
        <ul className='nav'>
            {menu.map((a, ai) => {
                return (
                    <li key={ai} className={`${location.pathname.indexOf(a.to ?? 'no-path') !== -1 ? 'active' : ''}`}>
                        {(a.child ?? []).length > 0 ? (
                            <>
                                <span className='link-main'><i className={a.icon}></i><span className="flex-fill">{a.label}</span><i className="fas fa-chevron-down"></i></span>
                                <ul className="sub">
                                    {a.child.map((b, bi) => (
                                        <li key={bi} className={(location.pathname.indexOf(b.to) !== -1 ? 'active' : '')}>
                                            <Link className='link-sub' to={b.to}><i className={b.icon}></i><span className="flex-fill">{b.label}</span></Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <Link className="link-main" to={a.to ?? '/admin'}><i className="fas fa-building"></i><span className="flex-fill">{a.label}</span></Link>
                        )}
                    </li>
                )
            })}
        </ul>
    )
}

export default Menu