import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_ENVIRONMENT_LIST = {
    type: 'ENVIRONMENT_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_ENVIRONMENT_VIEW = {
    type: 'ENVIRONMENT_VIEW',
    payload: {
        software: {},
        customer: {},
        domain: '',
    },
};

let callEnvironmentListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/software/environment?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_ENVIRONMENT_LIST.payload.rows = data.rows
        ACTION_ENVIRONMENT_LIST.payload.total = data.total
        ACTION_ENVIRONMENT_LIST.payload.pagination = data.pagination
        dispatch(ACTION_ENVIRONMENT_LIST)
    })
}

let callEnvironmentViewGet = (id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/software/environment/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_ENVIRONMENT_VIEW.payload.software = data.software
        ACTION_ENVIRONMENT_VIEW.payload.customer = data.customer
        ACTION_ENVIRONMENT_VIEW.payload.domain = data.domain
        dispatch(ACTION_ENVIRONMENT_VIEW)
    })
}

let callEnvironmentPost = (data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/admin/software/environment', data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callEnvironmentPut = (id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put('/admin/software/environment/' + id, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callEnvironmentClearView = () => (dispatch) => {
    ACTION_ENVIRONMENT_VIEW.payload.software = {
        id: '',
        name: '',
    }
    ACTION_ENVIRONMENT_VIEW.payload.customer = {
        id: '',
        name: '',
    }
    ACTION_ENVIRONMENT_VIEW.payload.domain = ''
    dispatch(ACTION_ENVIRONMENT_VIEW)
}

let callEnvironmentDelete = (id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete('/admin/software/environment/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

export { callEnvironmentListGet, callEnvironmentViewGet, callEnvironmentPost, callEnvironmentPut, callEnvironmentDelete, callEnvironmentClearView, ACTION_ENVIRONMENT_LIST, ACTION_ENVIRONMENT_VIEW }