import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import AdminReducer from './common/reducers';
import AppReducer from '../common/reducers';
import RegisterReducers from './modules/register/reducers';
import SoftwareReducer from './modules/software/reducers';

let reducers = combineReducers({
    admin: AdminReducer,
    app: AppReducer,
    register: RegisterReducers,
    software: SoftwareReducer,
});
let store = createStore(reducers, applyMiddleware(thunk))
export default store